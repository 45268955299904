import React, { ReactElement } from "react";

export default function CallCenterIcon({
    className,
    fill,
}: {
    className?: string;
    fill?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
            className={className ?? ""}
        >
            <path
                fill={fill ?? "#000"}
                fillRule="evenodd"
                d="M55.593 93.297a7.215 7.215 0 01-7.104 5.89c-3.988 0-7.221-3.22-7.221-7.19 0-3.972 3.233-7.19 7.221-7.19a7.225 7.225 0 016.423 3.898c19.102-2.683 29.983-11.136 32.641-25.357-1.133.603-2.692-.386-4.677-2.968 0 0-7.57 17.754-19.538 20.67 17.51-14.738 5.792-37.794 3.174-42.574-2.618-4.78-3.624-6.694-4.849.999-2.84 12.297-30.994 5.092-35.061 16.062-2.712 7.314.604 16.086 9.948 26.316-10.126-3.079-17.001-9.73-20.624-19.952C11.485 68.329 3.16 63.274 3 59.26V40.364c.004-3.38 1.985-5.488 5.942-6.322 8.587-42.238 69.546-45.8 80.114-1.489 4.971 0 7.62 1.975 7.944 5.923l-.426 18.425c.048 2.922-1.37 5.071-4.253 6.447-3.84 16.7-16.082 26.683-36.728 29.95zm-39.41-56.535C23.38 20.998 34.653 13.43 50 14.057c21.029.774 30.198 15.997 32.166 22.705l.91-.899C77.693 16.976 66.411 7.533 49.23 7.533c-17.181 0-28.464 9.443-33.847 28.33l.8.9z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
