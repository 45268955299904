import { ParsedUrlQuery } from "querystring";
export const gettingQueriesAsString = (
    query: ParsedUrlQuery,
    location: "start" | "inline",
    excludeList?: string[],
): string => {
    const initialValue = "";

    return Object.keys(query).reduce(function (
        previousValue,
        currentValue,
        index,
    ) {
        const value: string = query[currentValue] as string;

        if (excludeList?.includes(currentValue)) {
            return previousValue;
        }

        return (
            previousValue + `${index !== 0 ? "&" : ""}${currentValue}=${value}`
        );
    },
    initialValue);
};
