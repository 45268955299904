import React from "react";

function PhoneIcon({ color }: { color?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 25 25"
        >
            <mask
                id="mask0_1_8812"
                style={{ maskType: "alpha" }}
                width="25"
                height="25"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H25V25H0z"></path>
            </mask>
            <g mask="url(#mask0_1_8812)">
                <path
                    fill={color ?? "#323175"}
                    fillRule="evenodd"
                    d="M21.708 17.014l-2.079-2.08c-.582-.581-1.496-.581-1.995 0l-2.079 1.996c-1.497-.25-3.243-1.164-4.74-2.66-1.58-1.58-2.494-3.326-2.743-4.906l1.995-1.996c.582-.499.582-1.413 0-1.995L7.905 3.294a1.266 1.266 0 00-1.912 0c-.748.748-1.414 1.413-2.162 2.079-2.079 2.162.166 7.234 3.908 11.142 3.991 4.074 9.645 6.9 11.89 4.656.665-.748 1.33-1.414 2.079-2.162.582-.582.582-1.413 0-1.995zm-1.33-5.655c0 .333.332.666.665.666.416 0 .748-.333.748-.666 0-1.08-.25-2.161-.665-3.076-.416-.998-.998-1.913-1.746-2.66-.748-.75-1.663-1.331-2.66-1.747a7.561 7.561 0 00-3.077-.665c-.333 0-.666.332-.666.748 0 .333.333.665.666.665.914 0 1.746.167 2.577.5.832.332 1.58.83 2.162 1.496.665.582 1.164 1.33 1.497 2.162.332.831.499 1.663.499 2.577zm-2.661.167c0 .415.332.748.665.748.416 0 .665-.333.665-.748 0-.749-.083-1.414-.416-2.162-.249-.665-.665-1.247-1.164-1.83-.582-.498-1.164-.914-1.829-1.164-.748-.332-1.413-.415-2.162-.415-.416 0-.748.25-.748.665 0 .332.332.665.748.665.5 0 1.081.083 1.58.333.499.166.998.499 1.414.914.415.416.665.915.914 1.414.25.499.333.998.333 1.58z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default PhoneIcon;
