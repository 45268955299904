import React, { ReactElement } from "react";

export default function CloseIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="47"
            fill="none"
            viewBox="0 0 47 47"
        >
            <path d="M45.359 5.447A3.252 3.252 0 0045.35.85l.106.106a3.256 3.256 0 00-4.597-.009L23.865 17.941a1.003 1.003 0 01-1.424 0L5.447.947A3.252 3.252 0 00.85.956L.956.85a3.256 3.256 0 00-.009 4.597l16.994 16.994a1.003 1.003 0 010 1.424L.947 40.859a3.252 3.252 0 00.009 4.597L.85 45.35a3.256 3.256 0 004.597.009l16.994-16.994a1.003 1.003 0 011.424 0l16.994 16.994a3.252 3.252 0 004.597-.009l-.106.106a3.256 3.256 0 00.009-4.597L28.365 23.865a1.003 1.003 0 010-1.424L45.359 5.447z"></path>
        </svg>
    );
}
